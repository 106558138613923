<template>
  <div class="right-panel">
    <div v-if="!showPanel" />
    <div v-else class="panel-container">
      <div class="user-header">
        <div class="avatar" v-if="artist.avatar_url">
          <img :src="artist.avatar_url" />
        </div>
        <div class="initials" v-else>
          {{ artist.name | initials }}
        </div>
        <div class="user-details">
          <div class="name">
            <h1>{{ artist.name }}</h1>
          </div>
          <div class="ipi">
            <div class="publisher-ipi">
              <p class="title">Publisher IPI</p>
              <p class="val">{{ artist.ipi_publisher || "-" }}</p>
              <p class="val2">{{ artist.publisher_name }}</p>
            </div>
            <div class="songwriter-ipi">
              <p class="title">Songwriter IPI</p>
              <p class="val">{{ artist.ipi_songwriter || "-" }}</p>
            </div>
          </div>
          <div class="actions">
            <button
              class="primary"
              v-if="!viewingMyArtist"
              @click="showModal = true"
            >
              Create Deal
            </button>
            <!-- <div class="socials">
              <img src="../assets/icons/spotify.svg" />
              <img src="../assets/icons/itunes-color.svg" />
              <img src="../assets/icons/youtube.svg" />
              <img src="../assets/icons/instagram-color.svg" />
              <img src="../assets/icons/twitter.svg" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="history-container">
        <Loader
          color="#FF035A"
          size="40"
          v-if="$apollo.queries.history.loading"
        />
        <div v-else-if="history" class="history">
          <p class="title">
            <span>You </span> and <span>{{ artist.name }}</span>
          </p>
          <div class="history-item" v-if="!history.length">
            <span class="description">No Deals Yet.</span>
          </div>
          <div v-else>
            <div
              class="cursor-pointer history-item"
              v-for="{ id, song_id, time, text } in history"
              :key="id"
              @click="navigateSong(song_id)"
            >
              <span class="time">{{ time }}</span>
              <span class="description">{{ text }}</span>
              <img src="../assets/icons/Arrow-accent-right.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddSong
      v-if="showModal"
      @close="showModal = false"
      :artist="artist"
      :my-artist="myArtist"
    />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import AddSong from "@/components/modals/AddSong.vue";

import GET_DEALS_HISTORY from "@/api/queries/GET_DEALS_HISTORY.gql";

export default {
  name: "ContactPreview",
  components: {
    Loader,
    AddSong
  },
  props: {
    artists: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    myArtist() {
      return this.$store.getters["account/getArtist"];
    },
    viewingMyArtist() {
      return this.artist.id === this.myArtist.id;
    },
    artist() {
      const { artist } = this.$route.query;
      if (!artist) return {};
      return this.artists.find(el => el.id === artist) || {};
    },
    safeArtist() {
      return this.artist || {};
    },
    showPanel() {
      return Object.keys(this.safeArtist).length > 0;
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    formatHistory(deals) {
      return deals.map(({ id, song_id, created_at, initiator, from, to }) => {
        return {
          id,
          song_id,
          time: this.getHistoryTime(created_at),
          text: this.getHistoryText(initiator, from, to)
        };
      });
    },
    getHistoryTime(timestamp) {
      const created_at = new Date(timestamp);
      const now = new Date();
      const diff = new Date(now - created_at);
      const days = diff.getUTCDate() - 1;
      const hours = diff.getUTCHours();
      if (days) return `${days} day${days > 1 ? "s" : ""} ago`;
      else return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    },
    getHistoryText(initiator, from, to) {
      const isInitiator = this.myArtist.id === initiator;
      return isInitiator
        ? `You have requested a feature from ${to.name ||
            to.artist_users[0].user.email}.`
        : `${from.name ||
            to.artist_users[0].user.email} has requested a feature from you.`;
    },
    navigateSong(id) {
      this.$router.push({ name: "Catalog", query: { song: id } });
    }
  },
  apollo: {
    history: {
      query: GET_DEALS_HISTORY,
      variables() {
        return {
          myArtistId: this.myArtist.id,
          artistId: this.artist.id
        };
      },
      update({ deals }) {
        return this.formatHistory(deals);
      },
      skip() {
        return !this.showPanel;
      },
      error(e) {
        window.location.reload();
        console.log("error", e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.right-panel {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  .panel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 40px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .user-header {
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar {
        img {
          width: 10rem;
          max-height: 10rem;
          object-fit: cover;
          border-radius: 50%;
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.0001));
        }
      }

      .initials {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
        width: 10rem;
        font-family: sans-serif;
        font-size: 70px;
        background-color: $black;
        border: 1px solid $accent;
        color: $white;
        border-radius: 50%;
      }

      .user-details {
        margin-left: 3.5rem;

        .name {
          display: flex;

          h1 {
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 2.5rem;
            line-height: 46px;
            letter-spacing: -0.666667px;
            color: $text;
          }
        }

        .ipi {
          display: flex;
          margin-top: 2rem;

          .title {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text;
          }

          .val {
            margin-top: 5px;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 1px;
            color: $accent;
          }

          .val2 {
            margin-top: 5px;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text;
          }

          .songwriter-ipi {
            margin-left: 85px;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          margin-top: 3rem;

          .socials {
            display: flex;
            margin-left: 1.5rem;

            img {
              height: 23px;
              margin-right: 16px;
              cursor: pointer;
              -webkit-filter: grayscale(100%);
              filter: grayscale(100%);

              &:hover {
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
              }
            }
          }
        }
      }
    }

    .history-container {
      flex: 1;

      .history {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;

        .title {
          @include category-medium();
          color: $text;
          margin-bottom: 16px;

          span {
            font-weight: bold;
          }
        }

        .history-item {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          padding: 13px 24px;
          border-radius: 6px;
          background: $gray;

          .time {
            @include date-time-light();
            color: $text;
            width: 5rem;
          }

          .description {
            @include body-light();
            color: $text;
            flex: 1;
            margin-left: 20px;
          }
        }

        .view-all {
          display: flex;
          justify-content: center;

          a {
            text-align: center;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.22px;
            text-decoration-line: underline;
            color: $text;
          }
        }
      }
    }
  }
}
</style>
